import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';

import { fromBoundsToPointBox } from '../../../helpers/boundsManage';
import { PointDataFrameDef } from '../../../model/definitions/PointDataFrameDef';
import { SymbolPointType } from '../../../model/definitions/SymbolLayerDef';
import { axiosInstancePoint } from '../axiosInstancePoint';

export const useGetSymbolSources = (
  bounds: [number, number, number, number],
  pointType: SymbolPointType,
) => {
  return useQuery(['getSymbolSources', bounds, pointType], async () => {
    const response = await axiosInstancePoint.get<
      any,
      AxiosResponse<{ dataProducts: { sourceName: string; dataProductId: string }[] }>
    >(`${pointType.toLowerCase()}/divep/sources`, {});
    return response.data;
  });
};
export const useGetSymbolParameters = (
  dataProductId: string,
  bounds: [number, number, number, number],
  pointType: SymbolPointType,
) => {
  return useQuery(
    ['getSymbolParameters', bounds, pointType],
    async () => {
      const boundingBox = fromBoundsToPointBox(bounds);
      const response = await axiosInstancePoint.post<
        any,
        AxiosResponse<{ parameters: { name: string }[] }>
      >(`${pointType.toLowerCase()}/symbol-layer/parameters`, {
        dataProductId,
        boundingBox,
      });
      return response.data;
    },
    {
      enabled: !!dataProductId,
    },
  );
};

export const useGetSymbolTimestamps = (
  dataProductId: string,
  parameter: string | undefined,
  bounds: [number, number, number, number],
  pointType: SymbolPointType,
  timezoneId: string,
) => {
  return useQuery(
    ['getSymbolTimestamps', parameter, bounds, pointType, timezoneId],
    async () => {
      const boundingBox = fromBoundsToPointBox(bounds);
      const response = await axiosInstancePoint.post<
        any,
        AxiosResponse<{ timestamps: PointDataFrameDef[] }>
      >(`${pointType.toLowerCase()}/symbol-layer/timestamps`, {
        dataProductId,
        boundingBox,
        parameter,
        timezoneId,
      });
      return response.data;
    },
    {
      enabled: !!dataProductId && !!parameter,
    },
  );
};
