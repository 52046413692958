import { PartialLottieComponentProps, useLottie } from 'lottie-react';
import { useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { PlaybackEnum } from '../core/ui/enums/PlaybackEnum';
import { useMultimediaObjectUrl } from '../hooks/useMultimediaObjectUrl';
import { TimeControlDef } from '../model/definitions/TimeControlDef';
import playerContext from '../pages/playground/playerContext/PlayerContext';
import { ActiveDef } from '../store/slices/active-slice';
import { RootState } from '../store/store';

interface LottieIconProps extends PartialLottieComponentProps {
  versionId: string;
  inCanvas?: boolean;
  time?: TimeControlDef[];
  parentStart?: number;
  frameRate?: number;
  repeat?: boolean;
}

export const LottieIconAnimation = ({
  versionId,
  time = [new TimeControlDef(0, 0)],
  parentStart = 0,
  frameRate = 25,
  repeat = false,
  ...rest
}: LottieIconProps) => {
  const { activeFramerate } = useSelector<RootState>((state) => state.active) as ActiveDef;
  const url = useMultimediaObjectUrl(versionId, true);
  const { isPlaying, time: contextTime } = useContext(playerContext);

  const options = useMemo(
    () => ({
      ...rest,
      animationData: url,
      loop: !!repeat,
      autoplay: true,
      frameRate: activeFramerate,
      className: 'max-h-full max-w-full contents',
    }),
    [url, repeat, activeFramerate, rest],
  );

  const { View, goToAndStop, goToAndPlay, pause, stop, animationItem } = useLottie(options);

  const startMS = useMemo(() => parentStart + (time[0]?.startMS || 0), [parentStart, time]);

  const handlePlayback = () => {
    const frameRate = animationItem?.frameRate ?? activeFramerate;
    const totalFrames = animationItem?.totalFrames ?? 0;
    const elapsedFrames = (contextTime - startMS) / frameRate;

    if (isPlaying === PlaybackEnum.PLAYING) {
      if (contextTime >= startMS) {
        if (repeat) {
          goToAndPlay(elapsedFrames % totalFrames, true);
        } else if (elapsedFrames >= totalFrames) {
          goToAndStop(totalFrames - 1, true);
        } else {
          goToAndPlay(elapsedFrames, true);
        }
      } else {
        stop();
      }
    } else {
      pause();
      const targetFrame = Math.min(elapsedFrames, totalFrames - 1);
      goToAndStop(repeat ? elapsedFrames % totalFrames : targetFrame, true);
    }
  };

  useEffect(() => {
    handlePlayback();
  }, [contextTime, frameRate, isPlaying]);

  useEffect(() => {
    if (contextTime <= 0 && isPlaying !== PlaybackEnum.PLAYING) {
      goToAndStop(0, true);
    }
  }, [contextTime, goToAndStop, isPlaying]);

  return <>{View}</>;
};
