import { sortedUniqBy } from 'lodash';

import { DataFrameDef } from '../model/definitions/DataFrameDef';

function findMostFrequent(array: number[]): number {
  if (array.length === 0) {
    return 0; // Return null for empty array
  }

  const frequencyMap: { [key: number]: number } = {};
  let maxFrequency = 0;
  let mostFrequentElement = array[0]; // Initialize with the first element

  array.forEach(function (element) {
    // Count the frequency of each element
    frequencyMap[element] = (frequencyMap[element] || 0) + 1;

    // Update maxFrequency and mostFrequentElement if necessary
    if (frequencyMap[element] > maxFrequency) {
      maxFrequency = frequencyMap[element];
      mostFrequentElement = element;
    }
  });

  return mostFrequentElement;
}
const getMedianDifferenceFromTimestamp = (frames: DataFrameDef[]) => {
  const timestamps: number[] = frames.map((item) => item.timestamp * 1000); // Convert to milliseconds

  // Calculate differences between consecutive timestamps in seconds
  const differencesInSeconds: number[] = timestamps
    .slice(1)
    .map((timestamp, index) => (timestamp - timestamps[index]) / 1000);

  // Sort the differences
  const sortedDifferences = differencesInSeconds.sort((a, b) => a - b);

  // Find the median of the sorted differences
  /*   const medianIndex = Math.floor(sortedDifferences.length / 2);
  return sortedDifferences.length % 2 === 0
    ? (sortedDifferences[medianIndex - 1] + sortedDifferences[medianIndex]) / 2
    : sortedDifferences[medianIndex]; */
  return findMostFrequent(sortedDifferences);
};

const getNormalisedFrames = (data: DataFrameDef[]) => {
  if (!data || data.length === 0) return [];

  // Round timestamps to the nearest minute and sort by timestamp
  const frames = data
    .map((d) => ({
      ...d,
      timestamp: Math.round(d.timestamp / 60) * 60,
    }))
    .sort((a, b) => a.timestamp - b.timestamp);

  // Remove duplicates based on timestamp
  const uniqueFrames = sortedUniqBy(frames, 'timestamp');

  const updatedFrames = [];
  const medianDifference = Math.round(getMedianDifferenceFromTimestamp(uniqueFrames));

  for (let i = 0; i < uniqueFrames.length - 1; i++) {
    const currentFrame = uniqueFrames[i];
    const nextFrame = uniqueFrames[i + 1];

    updatedFrames.push(currentFrame);

    const timestampDifference = nextFrame.timestamp - currentFrame.timestamp;

    // If there's a gap, fill it with the previous frameId, ensuring equal intervals
    if (timestampDifference > medianDifference) {
      let currentTimestamp = currentFrame.timestamp;

      while (currentTimestamp + medianDifference < nextFrame.timestamp) {
        currentTimestamp += medianDifference;
        updatedFrames.push({
          frameId: currentFrame.frameId,
          timestamp: currentTimestamp,
        });
      }
    }
  }

  // Add the last frame
  updatedFrames.push(uniqueFrames[uniqueFrames.length - 1]);

  return updatedFrames;
};
export { getMedianDifferenceFromTimestamp, getNormalisedFrames };
