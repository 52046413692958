import { notification, Select } from 'antd';
import { Button, ToggleSwitch } from 'flowbite-react';
import Slider from 'rc-slider';
import React, { useEffect, useMemo, useState } from 'react';
import { AiOutlineCaretDown, AiOutlineCaretUp } from 'react-icons/ai';
import { MdUndo } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

import { useApplyBiasGroupOnMap } from '../../../core/api/bias/bias-group/useApplyBiasGroupOnMap';
import { useGetBiasGroups } from '../../../core/api/bias/bias-group/useGetBiasGroups';
import { useGetOceanMask } from '../../../core/api/mapLayers/useGetOceanMask';
import { useDebounce } from '../../../hooks/useDebounce';
import { usePropertyGridActive } from '../../../hooks/usePropertyGridActive';
import { GVNSP_RING_WIDTH_MARKS, MAX_ZINDEX_VALUE } from '../../../model/constants/constants';
import { MapPanelDef } from '../../../model/definitions/MapPanelDef';
import { MapPositionControlDef } from '../../../model/definitions/MapPositionControlDef';
import {
  getKilometersShownOnMap,
  getZoomForKilometersShownOnMap,
  hasAnyWdLayer,
} from '../../../molecules/mapElement/helpers';
import { getProjectionNameByCode } from '../../../molecules/mapElement/projetions';
import {
  ActiveDef,
  setMapAllowedZoom,
  setPropertyGridActiveHash,
} from '../../../store/slices/active-slice';
import {
  replaceMapInScene,
  setBaseMapHidden,
  setOceanMaskHidden,
  updateMapDefPartial,
  updateMapLayer,
} from '../../../store/slices/project-slice';
import { RootState } from '../../../store/store';
import InputNumber from '../../marketplace-new/atoms/FormatNumber/FormatNumber';
import { MapsRenderCache } from '../MapsRenderCache';
import { PaletteColorPicker } from './mapLayersProperties/PalettecolorPicker';
import styles from './Properties.module.scss';
import GridActions from './shared/GridActions';
import GridItem from './shared/GridItem';
import GridWrapper from './shared/GridWrapper';

type Props = {
  mapLayer: MapPanelDef;
};
const { Option } = Select;
const usedProps = [
  'mapPositionControl.longitude',
  'mapPositionControl.latitude',
  'mapPositionControl.zoom',
];

function MapPosition({ mapLayer }: Props) {
  const dispatch = useDispatch();
  const { activeScene, activeElement, mapsAllowedZoom, activeAspectRatio } = useSelector<
    RootState,
    ActiveDef
  >((state) => state.active);
  const kilometers = getKilometersShownOnMap(activeAspectRatio, mapLayer.mapPositionControl.zoom);
  const { data: biasFiltersObserved } = useGetBiasGroups('OBSERVED');
  const { data: biasFiltersForecast } = useGetBiasGroups('FORECAST');
  const { mutate: applyBias, isLoading } = useApplyBiasGroupOnMap();

  const isGVNSP = mapLayer.baseMapSetup?.baseMapConfigurationProj4?.includes('+proj=nsper');

  const currentMapState = useSelector<RootState, MapPositionControlDef>(
    (state) => state.mapState.mapState,
  );
  const { mutate: getOcean } = useGetOceanMask();
  const hasAnyWdLayerValue = useMemo(() => {
    return hasAnyWdLayer(mapLayer.wdSpace);
  }, [mapLayer.wdSpace]);

  const isZoomDisabled = useMemo(() => {
    return hasAnyWdLayerValue && !mapsAllowedZoom[mapLayer.id];
  }, [hasAnyWdLayerValue, mapLayer.id, mapsAllowedZoom]);

  const { lastFocused, isOpened } = usePropertyGridActive(usedProps);
  const [open, setOpen] = React.useState<boolean>(isOpened);
  async function onMapLayerChange(
    propertyPath: Leaves<MapPanelDef> | keyof MapPanelDef,
    newValue: any,
  ) {
    const updateView = propertyPath !== 'name';
    if (propertyPath === 'mapPositionControl.zoom') {
      newValue = getZoomForKilometersShownOnMap(activeAspectRatio, Number(newValue) || 1);
    }
    dispatch(
      updateMapLayer({
        activeScene,
        newValue: updateView,
        elementId: activeElement,
        propertyPath: 'mapPositionControl.updateView',
      }),
    );
    dispatch(
      updateMapLayer({
        activeScene,
        newValue,
        elementId: activeElement,
        propertyPath,
      }),
    );
  }

  function setDefaultPositionFromState() {
    dispatch(
      updateMapDefPartial({
        activeScene,
        elementId: activeElement,
        mapDefPartial: { mapPositionControl: currentMapState },
      }),
    );
  }

  function onFocus(path: string) {
    dispatch(setPropertyGridActiveHash({ activeElement, focusedEl: path }));
  }

  const onChangeHidden = (checked: boolean) => {
    dispatch(setBaseMapHidden({ activeScene, mapId: activeElement, value: !checked }));
  };
  const applyColor = () => {
    getOcean(
      {
        baseMap: {
          ...mapLayer.baseMapSetup,
          zindex: mapLayer.oceanMask.zindex,
          oceanLayerColor: mapLayer.oceanMask.oceanLayerColor,
        },
        inverted: false,
      },
      {
        onSuccess: async (res) => {
          await dispatch(setOceanMaskHidden({ activeScene, mapId: activeElement, value: true }));
          await dispatch(
            updateMapLayer({
              activeScene,
              newValue: res,
              elementId: activeElement,
              propertyPath: 'oceanMask',
            }),
          );
          await dispatch(setOceanMaskHidden({ activeScene, mapId: activeElement, value: false }));
        },
      },
    );
  };
  const onChangeOcean = (checked: boolean) => {
    if (mapLayer.oceanMask?.baseMapUrl)
      dispatch(setOceanMaskHidden({ activeScene, mapId: activeElement, value: !checked }));
    else {
      getOcean(
        {
          baseMap: mapLayer.baseMapSetup,
          inverted: false,
        },
        {
          onSuccess: (res) => {
            dispatch(
              updateMapLayer({
                activeScene,
                newValue: res,
                elementId: activeElement,
                propertyPath: 'oceanMask',
              }),
            );
          },
        },
      );
    }
  };
  const onChangeAllowedZoom = (checked: boolean) => {
    dispatch(setMapAllowedZoom({ mapId: activeElement, allow: checked }));
  };

  const changeOceanColor = async (e: string) => {
    if (mapLayer?.oceanMask) {
      await onMapLayerChange('oceanMask.oceanLayerColor', e);
    }
  };

  const [kilometersValue, setKilometersValue] = useState(kilometers);
  const debounceKilometers = useDebounce(kilometersValue);

  useEffect(() => {
    if (
      Math.round(
        getZoomForKilometersShownOnMap(activeAspectRatio, Number(debounceKilometers) || 1) * 10000,
      ) /
        10000 !==
      Math.round(mapLayer.mapPositionControl.zoom * 10000) / 10000
    ) {
      onMapLayerChange('mapPositionControl.zoom', debounceKilometers);
      const olMap = MapsRenderCache.get(mapLayer.id);
      if (olMap) {
        setTimeout(() => {
          const res = olMap.getView().getResolution();
          // @ts-ignore
          onMapLayerChange('properties.mapResolution', res);
        }, 300);
      }
    }
  }, [debounceKilometers]);
  const applyBiasGroupOnMap = (type: 'FORECAST' | 'OBSERVED', id: string) => {
    applyBias(
      { type, biasGroupId: id, body: mapLayer },
      {
        onSuccess: (data) => {
          dispatch(replaceMapInScene({ activeScene, mapPanel: data }));
          notification.success({ message: 'Group Filter Applied!' });
        },
      },
    );
  };
  const filterSelectorObserved = () => {
    const options = biasFiltersObserved?.map((filter) => (
      <Option value={filter.id} key={filter.id}>
        {filter.name}
      </Option>
    ));
    return (
      <Select
        disabled={isLoading}
        style={{ maxWidth: 200 }}
        onChange={(e) => applyBiasGroupOnMap('OBSERVED', e)}
      >
        {options}
      </Select>
    );
  };
  const filterSelectorForecast = () => {
    const options = biasFiltersForecast?.map((filter) => (
      <Option value={filter.id} key={filter.id}>
        {filter.name}
      </Option>
    ));
    return (
      <Select
        disabled={isLoading}
        style={{ maxWidth: 200 }}
        onChange={(e) => applyBiasGroupOnMap('FORECAST', e)}
      >
        {options}
      </Select>
    );
  };

  useEffect(() => {
    if (kilometersValue !== kilometers) {
      setKilometersValue(kilometers);
    }
  }, [kilometers]);

  return (
    <>
      <div
        className={`mb-2 subheader layer-header ${open ? 'layer-header-active' : ''}`}
        onClick={() => setOpen(!open)}
        style={{ marginTop: '1rem' }}
      >
        {open ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}
        {mapLayer?.name ? mapLayer.name : 'Map properties'}
      </div>
      {open && (
        <div className="prop-wrapper">
          <GridWrapper>
            <GridItem
              label={'Name:'}
              item={
                <input
                  autoFocus={lastFocused === 'name'}
                  className={styles.inputWrap}
                  value={mapLayer?.name}
                  onChange={(e) => onMapLayerChange('name', e.target.value)}
                  onFocus={() => onFocus('mapPositionControl.longitude')}
                />
              }
            />
            <GridItem label={''} item={null} />
            <GridItem
              label={'Longitude:'}
              item={
                <input
                  autoFocus={lastFocused === 'mapPositionControl.longitude'}
                  className={styles.inputWrap}
                  value={mapLayer?.mapPositionControl?.longitude}
                  onChange={(e) => onMapLayerChange('mapPositionControl.longitude', e.target.value)}
                  type="number"
                  onFocus={() => onFocus('mapPositionControl.longitude')}
                />
              }
            />
            <GridItem
              label="Latitude:"
              item={
                <input
                  className={styles.inputWrap}
                  autoFocus={lastFocused === 'mapPositionControl.latitude'}
                  value={mapLayer?.mapPositionControl?.latitude}
                  onChange={(e) => onMapLayerChange('mapPositionControl.latitude', e.target.value)}
                  type="number"
                  onFocus={() => onFocus('mapPositionControl.latitude')}
                />
              }
            />
            <GridItem
              label={'Map Scale (km):'}
              item={
                <InputNumber
                  className={styles.inputWrap}
                  value={kilometersValue}
                  autoFocus={lastFocused === 'mapPositionControl.zoom'}
                  onInputChange={(e) => setKilometersValue(e)}
                  type="number"
                  disabled={isZoomDisabled}
                  min={0}
                  precision={2}
                  onFocus={() => onFocus('mapPositionControl.zoom')}
                />
              }
            />
            <GridItem
              label={'Projection:'}
              item={getProjectionNameByCode(mapLayer?.baseMapSetup?.projection?.name!)}
            />
            <GridItem
              noBorderBg
              label={`Show base map:`}
              item={
                <ToggleSwitch
                  checked={!mapLayer?.baseMapSetup?.hidden}
                  label={''}
                  onChange={onChangeHidden}
                />
              }
            />
            <GridItem
              noBorderBg
              label={`Enable zoom:`}
              item={
                <ToggleSwitch
                  disabled={!hasAnyWdLayerValue}
                  checked={!hasAnyWdLayerValue || mapsAllowedZoom[mapLayer?.id]}
                  label={''}
                  onChange={onChangeAllowedZoom}
                />
              }
            />
            <GridItem
              noBorderBg
              label={`Apply filter on obsv. data:`}
              item={filterSelectorObserved()}
            />
            <GridItem
              noBorderBg
              label={`Apply filter on for. data:`}
              item={filterSelectorForecast()}
            />
            <GridItem
              noBorderBg
              label={`Ocean mask color:`}
              item={
                <>
                  <PaletteColorPicker
                    onChange={(color) => changeOceanColor(color.replace(/\s/g, ''))}
                    value={
                      mapLayer.oceanMask?.oceanLayerColor
                        ? mapLayer.oceanMask?.oceanLayerColor
                        : 'rgba(255,255,255,255)'
                    }
                  />
                  <Button onClick={() => applyColor()}>Apply</Button>
                </>
              }
            />
            <GridItem
              noBorderBg
              label={`Ocean mask:`}
              item={
                <ToggleSwitch
                  checked={mapLayer?.oceanMask && !mapLayer?.oceanMask?.hidden}
                  label={''}
                  onChange={onChangeOcean}
                />
              }
            />
            {mapLayer?.oceanMask && !mapLayer?.oceanMask?.hidden && (
              <>
                <GridItem
                  label={`Ocean Layer level:`}
                  item={
                    <InputNumber
                      className={styles.inputWrap}
                      value={mapLayer?.oceanMask?.zindex}
                      onInputChange={(e) => onMapLayerChange('oceanMask.zindex', e)}
                      min={0}
                      max={MAX_ZINDEX_VALUE}
                      step={1}
                    />
                  }
                />
              </>
            )}
            {isGVNSP && (
              <>
                <GridItem
                  noBorderBg
                  label={`Ring from color:`}
                  item={
                    <PaletteColorPicker
                      value={mapLayer?.customBackgroundImage?.fromColor || 'rgba(0,0,0,255)'}
                      onChange={(color) =>
                        onMapLayerChange('customBackgroundImage.fromColor', color)
                      }
                    />
                  }
                />
                <GridItem
                  noBorderBg
                  label={`Ring to color:`}
                  item={
                    <PaletteColorPicker
                      value={mapLayer?.customBackgroundImage?.toColor || 'rgba(0,0,0,255)'}
                      onChange={(color) => onMapLayerChange('customBackgroundImage.toColor', color)}
                    />
                  }
                />
                <GridItem
                  noBorderBg
                  label={`Ring width:`}
                  itemStyle={{ flexGrow: 1 }}
                  item={
                    <Slider
                      marks={GVNSP_RING_WIDTH_MARKS}
                      min={30}
                      max={300}
                      onChange={(e) => onMapLayerChange('customBackgroundImage.width', e)}
                      value={mapLayer?.customBackgroundImage?.width ?? 30}
                    />
                  }
                />
              </>
            )}
          </GridWrapper>
          <GridActions>
            <Button color="purple" onClick={setDefaultPositionFromState}>
              Use as default position
            </Button>
            <Button
              onClick={() =>
                onMapLayerChange(
                  'mapPositionControl.flyToInitialPosition',
                  mapLayer.mapPositionControl?.flyToInitialPosition
                    ? mapLayer.mapPositionControl?.flyToInitialPosition + 1
                    : 1,
                )
              }
              color="light"
            >
              <MdUndo />
            </Button>
          </GridActions>
        </div>
      )}
    </>
  );
}

export default MapPosition;
